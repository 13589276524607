import "@/styles/globals.css";
import { AnimatePresence, motion } from "framer-motion";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

console.log(`····································

M     M    OOOO    M     M    OOOO    
MM   MM   O    O   MM   MM   O    O   
M M M M  O      O  M M M M  O      O  
M  M  M   O    O   M  M  M   O    O   
M     M    OOOO    M     M    OOOO    

····································
·                                  ·
·       BUILT BY MOMO STUDIO       ·
·   DESIGNED BY RAQUEL AGOSTINHO   ·
·    DEVELOPED BY CELSO ALMEIDA    ·
·        [ momostudio.pt ]         ·
·                                  ·
····································`);

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [isMobileMenu, setMobileMenu] = React.useState(false);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AnimatePresence mode="wait">
        <motion.div key={router.asPath}>
          <Component
            {...pageProps}
            isMobileMenu={isMobileMenu}
            setMobileMenu={setMobileMenu}
          />
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white fixed bottom-0 left-0 w-full h-full z-50 pointer-events-none"
          />
        </motion.div>
      </AnimatePresence>
    </QueryClientProvider>
  );
}
